import axios from 'axios';
import tenant from "@/helpers/tenant";

class HttpClient {
    constructor(options = {}) {
        this.instance = axios.create(options)
    }

    async get(apiPath, params = {}, headers = {}) {
        if (tenant.has()) headers.tenant = tenant.get();
        return axios({url: apiPath, method: 'GET', params: params, headers: headers});
    }

    async post(apiPath, params = {}, headers = {}) {
        if (tenant.has()) headers.tenant = tenant.get();
        return this.instance.post(apiPath, params, {headers: headers});
    }

    async put(apiPath, params = {}, headers = {}) {
        if (tenant.has()) headers.tenant = tenant.get();
        return this.instance.put(apiPath, params, {headers: headers});
    }

    async delete(apiPath, headers = {}) {
        if (tenant.has()) headers.tenant = tenant.get();
        return this.instance.delete(apiPath, {headers: headers});
    }
}

export default HttpClient;