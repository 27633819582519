const urlParams = new URLSearchParams(window.location.search);

export default {
    has() {
       return urlParams.has('tenant');
    },

    get() {
        return urlParams.get('tenant');
    },
};