<template>
    <div class="textarea" :class="{ 'form-group--error': validator && validator.$error }">
        <label v-if="label"> {{ label }} </label>
        <textarea class="ez-slim-scroll"
                ref="textarea"
                :placeholder="placeholder"
                :value="value" @input="updateText">
        </textarea>
        <span  v-if="error" class="has-error animated headShake">{{ error }}</span>
        <div v-if="validator && validator.$error">
            <span class="has-error animated headShake" v-if="!validator.required">{{$t('field required')}}</span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Textarea",
        props: {
            placeholder: {type: String, default: ''},
            change: {type: Function},
            value: {type: String},
            validator: null,
            label: {type: String},
            error: {type: String},
        },
        methods: {
            updateText() {
                this.$emit('input', this.$refs.textarea.value);
                if ( this.change ) this.change(this.$refs.textarea.value);
            }
        }
    }
</script>

<style scoped lang="scss">
    .textarea {
        --bg: white;
        position: relative;

        label {
            display: block;
            margin-bottom:5px;
        }

        textarea {
            background: var(--bg);
            border: 1px solid #ced4da;
            border-radius: 5px;
            color: #495057;
            min-height: 90px;
            height: 100%;
            font-size: 1rem;
            padding: 10px;
            transition: .15s ease-in-out;
            width: 100%;
            resize: none;

            &:focus {
               box-shadow: 0 3px 12px rgba(color(bg-gray2),.3);
            }
        }

        .has-error {
            position: absolute;
            bottom: -14px;
            font-size: .7rem;
            right: 5px;
            color: color(bg-red);
            font-weight: $font-medium;
        }

        @keyframes bounce {
            from,
            20%,
            53%,
            80%,
            to {
                -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
                animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
                -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
            }

            40%,
            43% {
                -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
                animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
                -webkit-transform: translate3d(0, -30px, 0);
                transform: translate3d(0, -30px, 0);
            }

            70% {
                -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
                animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
                -webkit-transform: translate3d(0, -15px, 0);
                transform: translate3d(0, -15px, 0);
            }

            90% {
                -webkit-transform: translate3d(0, -4px, 0);
                transform: translate3d(0, -4px, 0);
            }
        }

        .bounce {
            -webkit-animation-name: bounce;
            animation-name: bounce;
            -webkit-transform-origin: center bottom;
            transform-origin: center bottom;
        }
    }
</style>
