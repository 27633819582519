import Vue from 'vue';
import Router from 'vue-router';

import Feedback from "@/components/Feedback/index.vue";

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            name: 'Feedback',
            component: Feedback,
            path: '/feedback/:feedback_id'
        },
    ]
});

export default router;