<template>
    <div id="app">
       <Feedback />
    </div>
</template>

<script>
    import Feedback from './components/Feedback/index.vue';

    export default {
        name: 'app',
        components: {
            Feedback
        }
    }
</script>

<style lang="scss">
    * {
        box-sizing: border-box;
        margin: 0;
        outline: none;
        padding: 0;
    }

    #app {
        font-family: 'Avenir', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
</style>