import Validations from '@/helpers/validation';
import LocalStore from "@/helpers/LocalStore";

import FeedbackService from '@/services/feedback.service';

const service = new FeedbackService();

export default {
    name: "Feedback",

    data() {
        return {
            step: "Home",
            subStep: {
                Name: "",
                Url: "",
            },
            subStepIndex: -1,
            comments: "",
            feedback: null,
            style: {
                background: '#fff',
            },
            validation: {},
            showExpired: false,
            show404: false,
        }
    },

    validations: Validations,

    async mounted() {
        await this.getFeedback();

        if (this.feedback) {
            this.setLanguage(this.feedback.Language);

            if (!(this.feedback.Status === 'reviewed' || this.feedback.Status === 'cancelled'))
                await this.editStatus('opened');
        }
    },

    methods: {
        async getFeedback() {
            const res = await service.get(this.$route.params.feedback_id);
            if (res) {
                if (!res.error)
                    this.feedback = res.data.feedback;
                else if (res.data.expired)
                    this.showExpired = true;
            } else
                this.show404 = true;
        },

        async editStatus(status) {
            if (!status) return;

            await service.edit({
                _id: this.$route.params.feedback_id,
                InviteStatus: status,
            });
        },

        async sendFeedback(recommend) {
            if (this.$v.comments.$invalid) {
                this.validation = {comments: "comments_validation"};
                return;
            }

            const res = await service.edit({
                _id: this.$route.params.feedback_id,
                Comments: this.comments,
                InviteStatus: "reviewed",
                Recommend: recommend || "no",
            });
            if (res && !res.error) {
                if (recommend !== "yes") this.step = "Form_Sent";
                this.validation = {};
                this.comments = "";
            }
        },

        async forwardUrl(url, socialName) {
            this.comments = `Forwarded to ${socialName}`;

            await this.sendFeedback("yes");

            window.location.href = url;
        },

        changeStep(step) {
            this.step = step;
            this.step === "Social" ? this.changeSubStep() : this.subStepIndex = -1;
        },

        changeSubStep() {
            const subStepCount = this.feedback.SocialUrls.length;

            this.subStepIndex += 1;

            if (this.subStepIndex < subStepCount)
                this.subStep = this.feedback.SocialUrls[this.subStepIndex];
        },

        changeStyle() {
            const subStepCount = this.feedback.SocialUrls.length;

            if (this.subStepIndex > -1 && this.subStepIndex < subStepCount)
                this.style = this.styleColor(this.subStep.Color);
            else
                this.style = this.styleColor();
        },

        styleColor(color) {
            const hex = color || "#fff";
            return {
                background: `linear-gradient(${this.hexToRGBA(hex, .9)}, ${this.hexToRGBA(hex, 0.5)})`,
            };
        },

        hexToRGBA(hex, a) {
            let r = 0, g = 0, b = 0, _a = a || 1;

            // 3 digits
            if (hex.length === 4) {
                r = "0x" + hex[1] + hex[1];
                g = "0x" + hex[2] + hex[2];
                b = "0x" + hex[3] + hex[3];

                // 6 digits
            } else if (hex.length === 7) {
                r = "0x" + hex[1] + hex[2];
                g = "0x" + hex[3] + hex[4];
                b = "0x" + hex[5] + hex[6];
            }

            return `rgba(${+r}, ${+g}, ${+b}, ${_a})`;
        },

        setLanguage(lang) {
            LocalStore.setLang(lang);
            this.$i18n.locale = lang;
        },
    },

    watch: {
        subStepIndex: {
            handler() {
                this.changeStyle();
            }
        }
    }
};