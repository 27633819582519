export default {
    stage: process.env.NODE_ENV || 'development',
    production: {
        ws_server: "https://api.ezlead.io",
        calls_server: "https://calls.ezlead.io",
        inventory_server: "https://inventory.ezlead.io",
        files_server: "https://files.ezlead.io",
        cdn_server: "https://cdn.ezlead.io",
        program_server: "https://program.ezlead.io",
        support_center: "https://support.ezlead.io",
        car_data_server: "https://cardata.ezlead.io",
        billing: "https://billing.ezlead.io",
        mailing: "https://mailing.ezlead.io",
        stripe_key: "pk_test_ZeCNvUmcy6ApZnocLkz6qd4Y00AaAFFiJx",
        fb_app_id: "1733981123560634",
        fb_app_secret: "84d555e7c76b9e85594c231a6f0e9c53",
        google_app_id: "645261426945-2frg39r8ei598asagql7vfujg3qa0599.apps.googleusercontent.com",
        google_app_secret: "yMlf6cLddr3uroJeYyp_uwNT",
        google_app_key: "AIzaSyBB8f81cL4nvDUEH-tiT_lzQ5mX_Bgk6Po",
    },
    development: {
        ws_server: "http://localhost:5000",
        calls_server: "http://localhost:4000",
        inventory_server: "http://localhost:4001",
        files_server: "http://localhost:4003",
        cdn_server: "http://localhost:4005",
        program_server: "http://localhost:4004",
        support_center: "http://localhost:4010",
        car_data_server :"http://localhost:4013",
        billing: "http://localhost:4011",
        mailing: "http://localhost:4012",
        stripe_key: "pk_test_ZeCNvUmcy6ApZnocLkz6qd4Y00AaAFFiJx",
        fb_app_id: "731522720692741",
        fb_app_secret: "b358607ba86b21d76fdd85e99f6e71dd",
        google_app_id: "645261426945-2frg39r8ei598asagql7vfujg3qa0599.apps.googleusercontent.com",
        google_app_secret: "yMlf6cLddr3uroJeYyp_uwNT",
        google_app_key: "AIzaSyBB8f81cL4nvDUEH-tiT_lzQ5mX_Bgk6Po",
    },
    staging: {
        ws_server: "https://api.ezleadsdev.com",
        calls_server: "https://calls.ezleadsdev.com",
        inventory_server: "https://inventory.ezleadsdev.com",
        files_server: "https://files.ezleadsdev.com",
        cdn_server: "https://cdn.ezleadsdev.com",
        program_server: "https://program.ezleadsdev.com",
        support_center: "https://support.ezleadsdev.com",
        car_data_server: "https://cardata.ezleadsdev.com",
        billing: "https://billing.ezleadsdev.com",
        mailing: "https://mailing.ezleadsdev.com",
        stripe_key: "pk_test_ZeCNvUmcy6ApZnocLkz6qd4Y00AaAFFiJx",
        fb_app_id: "1733981123560634",
        fb_app_secret: "84d555e7c76b9e85594c231a6f0e9c53",
        google_app_id: "645261426945-2frg39r8ei598asagql7vfujg3qa0599.apps.googleusercontent.com",
        google_app_secret: "yMlf6cLddr3uroJeYyp_uwNT",
        google_app_key: "AIzaSyBB8f81cL4nvDUEH-tiT_lzQ5mX_Bgk6Po",
    }

};
