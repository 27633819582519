import IService from './iservice';
import config from "../config";

const server_base = config[config.stage].ws_server;

class GeneralService extends IService {
    constructor(http) {
        super(http);
    }

    async get(id) {
        try {
            let response = await this.http.get(`${server_base}/api/feedback/public/${id}`);
            return response.data;
        } catch (e) {
            return null;
        }
    }

    async edit(feedback) {
        try {
            const response = await this.http.put(`${server_base}/api/feedback/public`, {feedback});
            return response.data;
        } catch (e) {
            return null;
        }
    }
}

export default GeneralService;
