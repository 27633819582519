import Vue from 'vue'
import Vuelidate from 'vuelidate';
import App from './App.vue'
import config from './config';
import {i18n} from '@/plugins/i18n';
import router from '@/router';

import '@/helpers/import.ui.components';

import CompanyService from './services/company.service';
const companies = new CompanyService();

const stage = config.stage;

Vue.config.productionTip = config[stage] !== "development";

Vue.use(Vuelidate);

companies.info()
    .then(res => {
        if (res && !res.error) {

            new Vue({
                router,
                i18n,
                render: h => h(App)
            }).$mount('#ez_leads_feedback');
        } else {
            // eslint-disable-next-line no-console
            console.error('Licensing Error');
        }
    })
    .catch(err => {
        // eslint-disable-next-line no-console
        console.log(err);
    });
